import React from 'react';
import { Link } from 'gatsby';

const Other = () => {
  return (
      <>
      <h2 className='text-2xl'>Other</h2>
      <p>under construction...</p>
        <Link to='/'>Back Home</Link>
      </>
  )
};

export default Other;
